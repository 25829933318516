<template>
  <div class="statement-wrap">
    <div class="txt-center">普瑞数安个人信息合规与保护云服务等级（SLA）协议</div>
    <p class="tab">本服务等级协议（以下简称“SLA”）是您与普瑞数安个人信息合规与保护云平台签订的《普瑞数安个人信息合规与保护云平台用户协议》（以下简称为“协议”）的不可分割的组成部分。协议中定义的术语在本SLA项下具有相同含义，但本SLA另有定义的情形除外。若本SLA的条款与条件与协议中的条款和条件相冲突或不一致，则以本SLA为准，但仅在该冲突或不一致范围内适用。</p>
    <p class="tab">普瑞数安个人信息合规与保护云平台会适时修改SLA协议，但在您的订购期限内，普瑞数安个人信息合规与保护云平台不会以实质性降低服务水平的方式修改您的SLA条款；如果您续订服务，则续订之时届时适用的SLA协议的版本将适用于您的续订期。您继续服务即代表您同意届时适用的SLA。</p>
    <div id="statement0">
      <p class="title">一、通用条款</p>
      <p class="tab">服务：为本SLA之目的，本SLA中提及的服务仅指本SLA中以下所列明的云服务。</p>
      <p class="tab">服务周期：一个服务周期为一个自然月。</p>
      <p class="tab">服务周期总时间：指每个服务周期内的总天数×24（小时）×60（分钟）</p>
      <p class="tab">服务不可用：将在下面的服务特定条款中针对每种服务进行定义。服务不可用不包含服务因下面所述的限制以及服务特定条款中的限制而发生不可用的情况。</p>
      <p class="tab">服务不可用时间：指一个服务周期内服务不可用的时间的总和，如不满一个月，仍按一个月计算。服务不可用时间不得重复计算，即每个服务周期的服务不可用时间不计入下一服务周期的服务不可用时间。</p>
      <p class="tab">服务可用性：指在一个服务周期内，针对本SLA中列明的服务，所承诺达到的服务可用率。</p>
      <p class="tab">月度服务费：指在一个服务周期（即自然月）内，客户就该服务周期内的服务所支付的服务费总额，如客户一次性支付多个服务周期的服务费，则将按照所购买的月数分摊计算月度服务费。</p>
      <p class="title">二、限制</p>
      <p class="tab">以下情形所导致的不可用不应被计入服务不可用时间：</p>
      <p class="tab">1)不可抗力的原因引起：</p>
      <p class="tab">2)依照法律法规、协议及协议中援引的政策您的服务被暂停或终止的；</p>
      <p class="tab">3)由于您或第三方原因导致的;</p>
      <p class="tab">4)系统或服务日常维护而引起的；</p>
      <p class="tab">5)由于您未按照服务使用文档或操作指导使用服务引起的；</p>
      <p class="tab">6)由于网络运营商的原因导致的服务不可用。</p>
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style scoped>
.statement-wrap{
  width: 1200px;
  margin: 0 auto;
}
.txt-center{
  font-size: 26px;
  margin-bottom: 30px;
  font-weight: bold;
  text-align: center;
}
.tab {
  text-indent: 2em;

}
.statement-wrap .title {
  font-weight: bold;
  font-size: 16px;
  line-height: 40px;
  border-left: none !important;
  padding-left: 0 !important;
}
</style>
